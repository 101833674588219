import classnames from "classnames";
import i18next from "i18next";
import { inject } from "mobx-react";
import { useEffect } from "react";
import { Translation } from "react-i18next";

import type { BlockFragment, OnAirShowFragment } from "@ihr-radioedit/inferno-webapi";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { getOnAirNow } from "../../services/OnAir";
import { ButtonKind, DateTimeDisplay, FauxButton } from "../../ui";
import { OnairScheduleThumb } from "../onair/OnairScheduleThumb.component";
import { ScheduleLinkWrapper } from "./ScheduleLinkWrapper.component";
import { LiveIcon } from "../icons/LiveIcon.component";

import styles from "./OnairTile.module.scss";
import type { Store } from "@inferno/renderer-shared-core";

interface OnairTileProps {
  current?: OnAirShowFragment | null;
  next?: OnAirShowFragment | null;
  cacheKey?: string;
  block: BlockFragment;
  store?: Store;
}

const startOnairRefresh = (store: Store, cacheKey: string, interval: number): ReturnType<typeof setInterval> | null => {
  if (!store || !cacheKey || !interval) {
    return null;
  }

  const refreshOnAirData = async () => {
    const data = await getOnAirNow({
      slug: store.site.index.slug,
      currentDate: store.env.TIME_OVERRIDE ? parseFloat(store.env.TIME_OVERRIDE) : undefined,
    });
    if (data) {
      store.storeCacheValue(cacheKey, { data });
    }
  };

  if (typeof window !== "undefined") {
    refreshOnAirData();
    return setInterval(refreshOnAirData, interval);
  }

  return null;
};

const stopOnairRefresh = (intervalId: ReturnType<typeof setInterval> | null) => {
  if (typeof window !== "undefined" && intervalId) {
    clearInterval(intervalId);
  }
};

export const OnairTile = inject("store")(({ store, current, next, cacheKey, block }: OnairTileProps) => {
  if (!store) {
    return null;
  }

  useEffect(() => {
    store.storeBlock({ ...block });
    const timestamp = store.env.ONAIR_ROTATOR_REFRESH ? parseInt(store.env.ONAIR_ROTATOR_REFRESH, 10) : 30000;
    if (cacheKey && typeof window !== "undefined") {
      const timer = startOnairRefresh(store, cacheKey, timestamp);
      return () => stopOnairRefresh(timer);
    }
  }, [store, cacheKey, block]);

  if (!current) {
    return null;
  }

  const { site } = store;
  const stationLogo = site.sections.design?.logo_favicon?.id || site.sections.design?.station_logo?.id;
  const currentTile = current ? (
    <Translation>
      {t => (
        <OnAirTileSchedule
          schedule={current}
          caption={t("onair")}
          context="current"
          stationLogo={stationLogo}
          liveIcon={true}
        />
      )}
    </Translation>
  ) : null;

  const scheduleClasses = classnames(styles.fullSchedule, {
    [styles.noBorder]: currentTile === null,
  });

  return (
    <div className={`${styles.componentOnairTile} themed-block`} data-cy="componentOnairTile">
      <nav className={styles.tileContainer}>
        {currentTile}
        <Translation>
          {t => (
            <OnAirTileSchedule
              schedule={next}
              caption={t("onairnext")}
              context="upcoming"
              stationLogo={stationLogo}
              liveIcon={false}
            />
          )}
        </Translation>
        <section className={scheduleClasses} data-cy="On-air-schedule">
          <FauxButton
            to={reverseRoute(site, "schedule") || "/schedule/"}
            context="full_schedule"
            kind={ButtonKind.SECONDARY}
            size="small"
          >
            {i18next.t("full_schedule")}
          </FauxButton>
        </section>
      </nav>
    </div>
  );
});

export interface OnAirTileScheduleProps {
  schedule?: OnAirShowFragment | null;
  caption: string;
  context: string;
  stationLogo: string | null | undefined;
  store?: Store;
  liveIcon: boolean;
}

export const OnAirTileSchedule = inject("store")(
  ({ schedule, caption, context, stationLogo, store, liveIcon }: OnAirTileScheduleProps) => {
    if (!store) {
      return null;
    }
    const { env } = store;
    let imgUrl = formatImage(stationLogo || "", env.IMAGE_HOST) || placeholderImage;
    let { name } = schedule || { name: store.site.sections.general?.name };
    let href: string | undefined;
    if (schedule?.showSite?.configByLookup) {
      name = schedule.showSite?.configByLookup?.sections?.general?.name || "";
      const image = schedule.showSite?.configByLookup?.sections?.general?.thumbnail_image?.asset?.href;
      if (schedule.showSite?.slug) {
        href = `/featured/${schedule.showSite?.slug}/`;
      }
      if (image) {
        imgUrl = image;
      }
    }

    const timezone = store.site.index.timeZone || "";

    return (
      <figure>
        <section className={styles.thumbContainer} data-cy="thumb">
          <ScheduleLinkWrapper href={href} context={context}>
            <Translation>
              {t => (
                <OnairScheduleThumb
                  alt={t("photo_of", { name: `${schedule?.name || ""}` })}
                  imgUrl={imgUrl}
                  min={40}
                  max={80}
                />
              )}
            </Translation>
          </ScheduleLinkWrapper>
        </section>
        <figcaption>
          <ScheduleLinkWrapper href={href} context={context}>
            {caption ? (
              <span data-cy="eyebrow" className={styles.eyebrowLike}>
                {caption}
              </span>
            ) : null}
            {schedule ? <DateTimeDisplay timestamp={schedule.startMs} zone_id={timezone} format="h:mm a" /> : null}
            {liveIcon ? <LiveIcon /> : null}
            <span data-cy="showTitle" className={styles.showTitle}>
              {name}
            </span>
          </ScheduleLinkWrapper>
        </figcaption>
      </figure>
    );
  },
);
