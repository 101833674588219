import { inject } from "mobx-react";

import { Remote } from "../remote/Remote.component";
import type { PageBlockInterface } from "../../page-blocks/Block.component";
import { getOnAirNow, getOnAirSlug } from "../../services/OnAir";
import { OnairPlayTile } from "./OnairPlayTile";
import { OnairTile } from "./OnairTile.component";
import type { Store } from "@inferno/renderer-shared-core";

interface OnairTileProps extends PageBlockInterface {
  store?: Store;
}

export const OnairTileLoader = inject("store")(({ store, block, isPrimary }: OnairTileProps) => {
  if (!store) {
    return null;
  }
  const slug = getOnAirSlug(store.site);
  const loader = () =>
    getOnAirNow({
      slug: store.site.index.slug,
      currentDate: store.env.TIME_OVERRIDE ? parseFloat(store.env.TIME_OVERRIDE) : undefined,
    });
  const cacheKey = `onairtileloader-${slug}`;
  return (
    <Remote loader={loader} cacheKey={cacheKey} showLoading={isPrimary}>
      {({ data }) => {
        return block.tags?.includes("display-hints/onair-show-play") ? (
          <OnairPlayTile current={data?.current} cacheKey={cacheKey} block={block} />
        ) : (
          <OnairTile current={data?.current} next={data?.upcoming} cacheKey={cacheKey} block={block} />
        );
      }}
    </Remote>
  );
});

export default OnairTileLoader;
