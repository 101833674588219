import * as React from "react";
import { MagicLink } from "../../ui";

export const ScheduleLinkWrapper = ({
  href,
  context,
  children,
  className,
}: {
  href?: string;
  context?: string;
  children: React.ReactNode;
  className?: string;
}) => {
  if (href) {
    return (
      <MagicLink to={href} context={context} className={className}>
        {children}
      </MagicLink>
    );
  }
  // Can't return just children, React doesn't like that
  return <React.Fragment>{children}</React.Fragment>;
};
