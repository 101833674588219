import { Site } from "@ihr-radioedit/inferno-core";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { getSdk, sdkOpts } from "@ihr-radioedit/inferno-core";

const sdk = getSdk(sdkOpts());

export const getOnAirSlug = (site: Site) => site.index.slug;

interface OnAirNow {
  current: Webapi.OnAirShowFragment | null;
  upcoming: Webapi.OnAirShowFragment | null;
  later: Webapi.OnAirShowFragment | null;
}

// Recalculate in case current on air is no longer current
export const refreshOnAir = (schedule: Webapi.OnAirScheduleFragment | undefined, now: number): OnAirNow => {
  const slots = [...(schedule?.upcoming || [])];
  if (schedule?.current) {
    slots.unshift(schedule.current);
  }
  let current: Webapi.OnAirShowFragment | null = null;
  let upcoming: Webapi.OnAirShowFragment | null = slots[0] || null;
  let later: Webapi.OnAirShowFragment | null = slots[1] || null;

  slots.forEach((slot, i) => {
    if (slot && slot.startMs <= now && slot.stopMs >= now) {
      current = slot;
      upcoming = slots[i + 1] || null;
      later = slots[i + 2] || null;
    }
  });

  return { current, upcoming, later };
};

export const getOnAirNow = (query: Webapi.GetOnAirNowQueryVariables): Promise<OnAirNow> =>
  sdk!.GetOnAirNow.queryAsPromise(query).then(r => {
    const schedule: Webapi.OnAirScheduleFragment | undefined = r.data?.sites.find?.onAirSchedule;
    const now = query.currentDate || Date.now();
    return refreshOnAir(schedule, now);
  });

export const getOnAirNowByDay = (query: Webapi.GetOnAirNowByDayQueryVariables) =>
  sdk!.GetOnAirNowByDay.queryAsPromise(query).then(r => {
    const response = r.data?.sites.find;
    if (!response) {
      return null;
    }
    const { onAirSchedule, onAirScheduleForDay, timeZone } = response;
    const { current } = refreshOnAir(onAirSchedule, query.currentDate || Date.now());
    return { current, schedule: onAirScheduleForDay.schedule, timeZone };
  });
