import type { SrcSet } from "@inferno/renderer-shared-core";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import { ResponsiveImage } from "../ResponsiveImage.component";
import { RESPONSIVE_IMAGE } from "../../ui/constants";

interface OnairScheduleThumbProps {
  imgUrl: string;
  alt: string;
  min?: number;
  max?: number;
}

export const OnairScheduleThumb = ({ imgUrl, alt, min = 70, max = 140 }: OnairScheduleThumbProps) => {
  const srcset: SrcSet[] = [
    {
      url: `${imgUrl}?ops=gravity(%22center%22),fit(${min},${min})`,
      descriptor: "1x",
    },
    {
      url: `${imgUrl}?ops=gravity(%22center%22),fit(${max},${max})`,
      descriptor: "2x",
    },
  ];

  return (
    <ResponsiveImage
      alt={alt}
      placeholder={`${placeholderImage}?ops=ratio(1,1)`}
      src={`${imgUrl}?ops=gravity(%22center%22),fit(${min},${min})`}
      srcset={srcset}
      initialWidth={RESPONSIVE_IMAGE.onAirScheduleThumbWidth}
      initialHeight={RESPONSIVE_IMAGE.onAirScheduleThumbHeight}
    />
  );
};
