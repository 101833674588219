export const LiveIcon = () => (
  <svg
    tabIndex={-1}
    focusable="false"
    className="svg-icon icon-live"
    width="33"
    height="15"
    viewBox="0 0 33 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="33" height="15" rx="2" fill="#D2252A" />
    <path tabIndex={-1} d="M10.291 9.78613H6.73535V3.24951H5.34961V11H10.291V9.78613Z" fill="white" />
    <path tabIndex={-1} d="M13.1699 11V3.24951H11.7842V11H13.1699Z" fill="white" />
    <path
      tabIndex={-1}
      d="M18.8096 11L21.4897 3.24951H20.0127L18.0791 9.3833H17.9878L16.0381 3.24951H14.5073L17.2144 11H18.8096Z"
      fill="white"
    />
    <path
      tabIndex={-1}
      d="M27.8438 9.80225H24.2129V7.63232H27.645V6.50439H24.2129V4.44727H27.8438V3.24951H22.8271V11H27.8438V9.80225Z"
      fill="white"
    />
  </svg>
);
