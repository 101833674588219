import { formatImage, placeholderImage } from "@ihr-radioedit/inferno-core";
import { inject, observer } from "mobx-react";
import { Fragment, useEffect, useState } from "react";
import type { BlockFragment, OnAirShowFragment } from "@ihr-radioedit/inferno-webapi";
import { LogoImg } from "../../chrome/StationLogo.component";
import { isWindowDefined, resizeWatcher } from "@inferno/renderer-shared-core";
import { getOnAirNow } from "../../services/OnAir";
import { Spinner } from "../../ui";
import { COMPONENT_BREAKPOINTS, RESPONSIVE_IMAGE } from "../../ui/constants";
import { MagicLink } from "@inferno/renderer-shared-ui";
import { LiveIcon } from "../icons/LiveIcon.component";
import { PlayIcon } from "../icons/PlayIcon.component";
import { StopIcon } from "../icons/StopIcon.component";
import styles from "./OnairPlayTile.module.scss";
import type { Store } from "@inferno/renderer-shared-core";

interface OnairTileProps {
  current?: OnAirShowFragment | null;
  cacheKey?: string;
  block: BlockFragment;
  store?: Store;
}

const startOnairRefresh = (store: Store, cacheKey: string, interval: number): ReturnType<typeof setInterval> | null => {
  if (!store || !cacheKey || !interval) {
    return null;
  }

  const refreshOnAirData = async () => {
    const data = await getOnAirNow({
      slug: store.site.index.slug,
      currentDate: store.env.TIME_OVERRIDE ? parseFloat(store.env.TIME_OVERRIDE) : undefined,
    });
    if (data) {
      store.storeCacheValue(cacheKey, { data });
    }
  };

  if (typeof window !== "undefined") {
    refreshOnAirData();
    return setInterval(refreshOnAirData, interval);
  }

  return null;
};

const stopOnairRefresh = (intervalId: ReturnType<typeof setInterval> | null) => {
  if (typeof window !== "undefined" && intervalId) {
    clearInterval(intervalId);
  }
};

const StreamStartIcon = ({ inProgress, playback }: { inProgress: boolean; playback: boolean }) => {
  const listenState = (comp: () => JSX.Element) => <Fragment>{comp()}</Fragment>;

  if (inProgress) {
    return <Spinner visible={true} />;
  }

  return playback ? listenState(StopIcon) : listenState(PlayIcon);
};

export const OnairPlayTile = inject("store")(
  observer(({ store, current, cacheKey, block }: OnairTileProps) => {
    const [dimensions, setDimensions] = useState<{ w: number; h: number }>({
      w: RESPONSIVE_IMAGE.stationLogoMobileWidth,
      h: RESPONSIVE_IMAGE.stationLogoMobileHeight,
    });

    if (!store) {
      return null;
    }

    useEffect(() => {
      store.storeBlock({ ...block });
      const timestamp = store.env.ONAIR_ROTATOR_REFRESH ? parseInt(store.env.ONAIR_ROTATOR_REFRESH, 10) : 30000;
      if (cacheKey && isWindowDefined()) {
        const timer = startOnairRefresh(store, cacheKey, timestamp);
        return () => stopOnairRefresh(timer);
      }
    }, [store, cacheKey, block]);

    useEffect(() => {
      const handleWindowResize = () => {
        if (window.innerWidth > COMPONENT_BREAKPOINTS.mobileBreakpoint) {
          setDimensions({
            w: RESPONSIVE_IMAGE.stationLogoDesktopWidth,
            h: RESPONSIVE_IMAGE.stationLogoDesktopWidth,
          });
        } else {
          setDimensions({
            w: RESPONSIVE_IMAGE.stationLogoMobileWidth,
            h: RESPONSIVE_IMAGE.stationLogoMobileWidth,
          });
        }
      };

      if (isWindowDefined()) {
        handleWindowResize();
        resizeWatcher.onWidthChange.subscribe(handleWindowResize);
        return () => resizeWatcher.onWidthChange.unsubscribe(handleWindowResize);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { playback, inProgress, togglePlayback } = store.player;
    const { site, env } = store;
    const stationLogo = site.sections.design?.station_logo?.id;
    const imgUrl = formatImage(stationLogo || "", env.IMAGE_HOST) || placeholderImage;
    const alt = `${site.sections.general?.name} - ${site.sections.general?.positioner}`;

    let currentOnair = null;
    if (current) {
      currentOnair = current.showSite?.slug ? (
        <MagicLink to={`/featured/${current.showSite?.slug}/`} context={"current"} className={styles.showTitle}>
          {current.showSite?.configByLookup?.sections?.general?.name || ""}
        </MagicLink>
      ) : (
        <span className={styles.showTitle} data-cy="showTitle">
          {current.showSite?.configByLookup?.sections?.general?.name || ""}
        </span>
      );
    }

    return (
      <div className={`${styles.componentOnairPlayTile} themed-block`} data-cy="componentOnairPlayTile">
        <section className={styles.streamStartWrapper} data-cy="streamStartWrapper">
          <div className={styles.streamStartThumb}>
            <button
              className={styles.playTrigger}
              onClick={() => togglePlayback(store, { sectionName: "onair" })}
              aria-label={`Play ${site.sections.general?.name} stream now`}
            >
              <span className={styles.hoverIcon}>
                <PlayIcon />
              </span>
              <LogoImg imgUrl={imgUrl} alt={`Logo for ${alt}`} width={dimensions.w} height={dimensions.h} />
            </button>
          </div>
          <div className={styles.streamStartDetails}>
            <h1>{site.sections.general?.name}</h1>
            <span className={styles.positioner}>{site.sections.general?.positioner}</span>
            <LiveIcon />
            {currentOnair}
            <button
              onClick={() => togglePlayback(store, { sectionName: "onair" })}
              className={styles.toggleBtn}
              aria-label="Play Stream Now"
            >
              <StreamStartIcon inProgress={inProgress} playback={playback} />
            </button>
          </div>
        </section>
      </div>
    );
  }),
);
